import React, { useState, useEffect, useContext } from 'react';
import { Table, Button } from 'antd';
import { CameraOutlined, FullscreenOutlined  } from '@ant-design/icons';
import Call from '../../../config/Call';
import Spinner from '../../base/spiner';
import ReferenciaInput from './components/ReferenciaInput';
import TallasButtons from './components/TallasButtons';
import ImageModal from './components/ImageModal';
import { EyeOutlined } from '@ant-design/icons';
import DetailModal from './components/DetailModal';
import AuthContext from '../../../contexts/auth/AuthContext';
import ModalAdvancedFilters from "./components/ModalAdvancedFilters";
import ColorModal from "./components/ColorModal";
import Swal from "sweetalert2";


const ConsultarReferencia = () => {
    const [toggleValue, setToggleValue] = useState(1);
    const [inputValue, setInputValue] = useState('');
    const [showSpinner, setShowSpinner] = useState(false);
    const [tallas, setTallas] = useState([]);
    const [tallaSeleccionada, setTallaSeleccionada] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [precio, setPrecio] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [dataSource, setDataSource] = useState([]);
    const [isModalEyeVisible, setIsModalEyeVisible] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [transitToggle, setTransitToggle] = useState(false);
    const [isModalVisibleFilter, setIsModalVisibleFilter] = useState(false);
    const [isColorModalVisible, setIsColorModalVisible] = useState(false);
    const [dataAdvance, setDataAdvance]= useState('');
    const [modalColor, setModalColor] = useState('');
    const [textoFilters, setTextoFilters]=useState('');

    const { user } = useContext(AuthContext)

    const handleToggleChange = (checked) => {
        setDataAdvance('')
        setTextoFilters('')
        setToggleValue(checked ? 0 : 1);
    };

    const handleInputChange = (e) => {
        setTallaSeleccionada('')
        if (e.target.value.length <= 12) {
            setInputValue(e.target.value);
        }
    };

    const handleTallaClick = (talla) => {
        setTallaSeleccionada((prevTalla) => (prevTalla === talla ? '' : talla));
    };

    const openModal = (url) => {
        setIsModalVisible(true);
        setImageUrl(url?.imagen || imageUrl)
    };

    const closeModal = () => {
        setIsModalVisible(false);
    };

    const resetData = () => {
        setTallas([]);
        setPrecio('');
        setImageUrl('');
        setTallaSeleccionada('');
        setTransitToggle(false);
        setToggleValue(1);
    };

    const columns = [
        {
            title: 'Color',
            dataIndex: 'color',
            key: 'color',
            width: '30px',
            align: 'center',
            render: (text) => <span style={{ fontWeight: 'bold' }}
                                    className={`${dataAdvance !==''? ' cursor-pointer': ''}`}
                                    onClick={()=> {openColorModal(text)}}>{text}</span>,
            onHeaderCell: () => ({
                style: {
                    position: 'sticky',
                    top: 0,
                    background: '#ffffff',
                    zIndex: 1,
                },
            }),
        },
        ...(tallaSeleccionada === '' ? [{
            title: 'Talla',
            dataIndex: `${dataAdvance !=='' ? 'tallas_disponibles' : 'talla'}`,
            align: 'center',
            key: 'talla',
            width: '30px',
            render: (text) => <span style={{ fontWeight: 'bold' }}>{dataAdvance !== '' ? text.join(" - "): text}</span>,
            onHeaderCell: () => ({
                style: {
                    position: 'sticky',
                    top: 0,
                    background: '#ffffff',
                    zIndex: 1,
                },
            }),
        }] : []),
        {
            title: 'Cantidad',
            dataIndex: `${dataAdvance !== ''? 'cantidad_total_modelos' : 'cantidad'}`,
            key: 'cantidad',
            align: 'center',
            width: '30px',
            render: (text) => <span style={{ fontWeight: 'bold' }}>{text}</span>,
            onHeaderCell: () => ({
                style: {
                    position: 'sticky',
                    top: 0,
                    background: '#ffffff',
                    zIndex: 1,
                },
            }),
        },
        ...(transitToggle ? [{
            title: 'Transito',
            dataIndex: 'transito',
            key: 'transito',
            align: 'center',
            width: '20px',
            render: (text) => <span style={{ fontWeight: 'bold' }}>{text}</span>,
            onHeaderCell: () => ({
                style: {
                    position: 'sticky',
                    top: 0,
                    background: '#ffffff',
                    zIndex: 1,
                },
            }),
        }] : []),
        ...(toggleValue === 0 ? [{
            title: 'Ver información',
            key: 'acciones',
            width: '50px',
            align: 'center',
            render: (text, record) => (
                <Button
                    type="link"
                    icon={<EyeOutlined style={{ fontSize: '25px' }} />}
                    onClick={() => showModal(record)}
                />
            ),
            onHeaderCell: () => ({
                style: {
                    position: 'sticky',
                    top: 0,
                    background: '#ffffff',
                    zIndex: 1,
                },
            }),
        }] : []),
        {
            title: 'Detalle',
            dataIndex: 'color',
            key: 'color',
            width: '30px',
            align: 'center',
            hidden: true,
            render: (text) => <span style={{ fontWeight: 'bold' }}
                                    className={`${dataAdvance !==''? ' cursor-pointer': ''}`}
                                   >
                <Button
                    type="link"
                    icon={<EyeOutlined style={{ fontSize: '25px' }} />}
                    onClick={() => openColorModal(text)}
                />
            </span>,
            onHeaderCell: () => ({
                style: {
                    position: 'sticky',
                    top: 0,
                    background: '#ffffff',
                    zIndex: 1,
                },
            }),
        },
    ].filter(col => dataAdvance!== '' ? col: !col.hidden );
    
    const showModal = (record) => {
        setSelectedRecord(record);
        setIsModalEyeVisible(true);
    };

    const closeModalEye = () => {
        setIsModalEyeVisible(false);
        setSelectedRecord(null);
    };

    useEffect(() => {

        if (inputValue === '' && dataAdvance === '') {
            resetData();
            return;
        }
        if(inputValue !== ''){
            setDataAdvance('')
            setTextoFilters('')
        }
    
        let timeoutId;
    
        const fetchData = async () => {
            const digitCount = inputValue.length;

            const fetchAndSetData = async () => {

                const infoFiltersAdvance=(dataAdvance !== '' ? ('&clasificacion='+dataAdvance) :'')
                const endpoint = `api/consulta-referencia/${inputValue === ''? null :inputValue }/?tipo=${toggleValue}&talla=${tallaSeleccionada}&transito=${transitToggle ? 1 : 0}${infoFiltersAdvance}`;

                try {
                    setShowSpinner(true);
                    const response = await Call('GET', endpoint, true, null, false);
                    let inventario = null
                    if(dataAdvance !== ''){
                        inventario= Object.entries(response.data?.inventario).map(([color, value]) => ({
                            color,
                            ...value
                        }));
                    }else{
                        inventario= response.data?.inventario.map((item, idx) => ({
                            ...item,
                            id: item.id || idx,
                        }));
                        setPrecio(inventario[0]?.precio || '');
                        setImageUrl(inventario[0]?.imagen || '');
                    }

                    setDataSource(inventario || []);
                    setTallas(response.data?.tallas || []);

                    if(dataAdvance !== ''){
                        setIsModalVisibleFilter(false);
                    }
                } catch (error) {
                    resetData();
                } finally {
                    setShowSpinner(false);
                }
            };

            if (digitCount === 6 || digitCount === 11 || digitCount === 12 || dataAdvance !== '') {
                timeoutId = setTimeout(fetchAndSetData, 1000);
            }
        };

        fetchData();

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [inputValue, toggleValue, tallaSeleccionada, transitToggle, dataAdvance]);

    const handleFullscreen = () => {
        if (document.fullscreenElement) {
            document.exitFullscreen();
        } else {
            document.documentElement.requestFullscreen();
        }
    };
    const closeModalFilter = () => {
        setIsModalVisibleFilter(false);
    };
    const closeColorModal= ()=>{
        setIsColorModalVisible(false);
    }
    const dataFilter = (data) => {

        const isEmpty= Object.keys(data).some(key => data[key] === '');
        if(!isEmpty){
            setDataAdvance(data.linea+data.sublinea+data.genero+data.silueta)
        }

    }
    const filterText=(data)=>{
        setTextoFilters(data)
    }
    const cleanDataAdvance=()=>{
        setIsModalVisibleFilter(true)
        setDataSource([])
        setTallas([])
        setInputValue('')
    }
    const openColorModal=(data)=>{
        if(dataAdvance !== ''){
            if(tallaSeleccionada !== ''){
                setIsColorModalVisible(true);
                setModalColor(data)
            }else{
                Swal.fire({
                    title: '',
                    icon: 'info',
                    html: 'Selecciona una talla',
                    focusConfirm: false,
                    confirmButtonText:'Aceptar',
                    allowOutsideClick: false,

                })
            }
        }
    }
    const handleTransitToggle=()=>{
        setDataAdvance('')
        setTextoFilters('')
        setTransitToggle(!transitToggle)

    }
    const cleanSearch=()=>{
        setTallas([])
        setInputValue('')
        setDataSource([])
        setDataAdvance('')
        setTallaSeleccionada('')
        setTextoFilters('')
        setModalColor('')
    }

    return (
        <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
            {showSpinner && <Spinner />}
            <div style={{ 
                maxWidth: '1100px', 
                width: '100%', 
                padding: '20px', 
                borderRadius: '8px', 
                backgroundColor: '#f9f9f9', 
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' 
            }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                <h3 style={{ 
                    color: '#333', 
                    backgroundColor: '#f5f5f5',
                    borderRadius: '8px',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                    padding: '10px 20px',
                    display: 'inline-flex', 
                    alignItems: 'center', 
                    gap: '10px',
                    margin: 0
                }}>
                    <img 
                        src="/static/media/cubo_rojo.0d26522b7aa5e844f49a.ico" 
                        alt="Icono"
                        style={{ width: '24px', height: '24px' }}
                    />
                    Consulta de referencias
                </h3>
                <Button 
                    onClick={handleFullscreen} 
                    icon={<FullscreenOutlined style={{ fontSize: '30px' }} />}
                    style={{
                        position: 'absolute',
                        right: 0,
                        border: 'none',
                        backgroundColor: 'transparent',
                        cursor: 'pointer',
                        fontSize: '30px',
                        color: '#333',
                    }}
                />
            </div>
                <div style={{ 
                    marginTop: '15px', 
                    padding: '10px 20px', 
                    backgroundColor: 'red', 
                    borderRadius: '8px', 
                    color: '#ffffff', 
                    fontWeight: 'bold', 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center', 
                    fontSize: '1.2rem' 
                }}>
                    <i className="fas fa-building" style={{ marginRight: '8px' }}></i>
                    {user && user.centro_costos.toUpperCase()}
                </div>

                <ReferenciaInput 
                    inputValue={inputValue}
                    onInputChange={handleInputChange}
                    toggleValue={toggleValue}
                    onToggleChange={handleToggleChange}
                    transitToggle={transitToggle}
                    onTransitToggleChange={handleTransitToggle}
                    cleanDataAdvance={cleanDataAdvance}
                    tallas={tallas}
                    cleanSearch={cleanSearch}
                />

                <TallasButtons 
                    tallas={tallas} 
                    tallaSeleccionada={tallaSeleccionada}
                    onTallaClick={handleTallaClick}
                />
                {((tallas.length > 0) && (dataAdvance === '')) && (
                    <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                        <div style={{ 
                            padding: '10px 20px',
                            borderRadius: '8px',
                            backgroundColor: 'red',
                            color: '#ffffff',
                            fontWeight: 'bold',
                            display: 'flex', 
                            alignItems: 'center', 
                            justifyContent: 'center', 
                            gap: '10px',
                            fontSize: '1.2rem'
                        }}>
                            <span>Precio: {new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(precio)}</span>
                            <Button 
                                onClick={openModal} 
                                type="link"
                                icon={<CameraOutlined style={{ color: '#ffffff', fontSize: '18px' }} />}
                            />
                        </div>
                    </div>
                )}
                {tallas.length > 0 && (
                <div style={{ maxHeight: '420px', overflowY: 'auto', position: 'relative' }}>
                    <style>
                        {`
                            .ant-table-header {
                                position: sticky;
                                top: 0;
                                background: #ffffff; /* Fondo blanco para el header */
                                z-index: 1; /* Para que el header se mantenga encima del contenido */
                            }
                        `}
                    </style>
                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        size="middle"
                        rowKey="id"
                        pagination={{ position: ['bottomCenter'], pageSize: 100 }}
                    />
                </div>
                )}
                {selectedRecord && (
                    <DetailModal
                    visible={isModalEyeVisible}
                    onClose={closeModalEye}
                    record={selectedRecord}
                    transitToggle={transitToggle}
                    />
                )}
            </div>

            {isModalVisible && (
                <ImageModal visible={isModalVisible} onClose={closeModal} imageUrl={imageUrl} />
            )}

            {isModalEyeVisible && selectedRecord && (
                <DetailModal
                    visible={isModalEyeVisible}
                    onClose={closeModalEye}
                    record={selectedRecord}
                    transitToggle={transitToggle}
                />
            )}
            {
                isModalVisibleFilter &&
                <ModalAdvancedFilters
                    visible={isModalVisibleFilter}
                    onClose={closeModalFilter}
                    filterAdavance={dataFilter}
                    textoFilters={filterText}
                />
            }
            {
                isColorModalVisible &&
                <ColorModal
                    visible={isColorModalVisible}
                    onClose={closeColorModal}
                    modelData={modalColor}
                    tallaSelected={tallaSeleccionada}
                    dataAdvance={dataAdvance}
                    textoFilters={textoFilters}
                    openModal={openModal}

                />
            }
        </div>
    );
};

export default ConsultarReferencia;
