import React, {useEffect, useState} from 'react';
import Call from '../../../../config/Call'
import Spiner from "../../../base/spiner"
import {Table, Modal, Button} from "antd"
import {CameraOutlined} from "@ant-design/icons";


const ColorModal = ({visible, onClose, modelData, tallaSelected, dataAdvance, openModal, textoFilters}) => {
    const [sp, setSp] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [descripcion , setDescripcion] = useState('');

    useEffect(() => {
        if(modelData !== ''){
            getReferences(modelData)
        }
        if(textoFilters !== ''){
            const texto=`${textoFilters.sublinea}, ${textoFilters.genero}, ${textoFilters.silueta}`;
            setDescripcion(texto)
        }
    }, [modelData]);

    const getReferences=async()=>{
        try{
            const color = modelData.replace(/ /g, "")
            const url = `/api/consulta-referencia/null/?tipo=1&talla=${tallaSelected}&transito=0&clasificacion=${dataAdvance}&color=${color}`

            setSp(true)

            const res= await Call('GET', url, true, null, false)

            const inventario= res?.data?.inventario
            setDataSource(inventario)

            setSp(false)
        }catch(error){

            setSp(false)

        }
    }
    const columns = [
        {
            title: 'Modelo',
            dataIndex: 'modelo',
            align: 'center',
            key: 'modelo',
            width: '30px',
            hidden: true,
            render: (text, row) => <span style={{fontWeight: 'bold', letterSpacing: '1px'}}>{text}</span>,
            onHeaderCell: () => ({
                style: {
                    position: 'sticky',
                    top: 0,
                    background: '#ffffff',
                    zIndex: 1,
                },
            }),
        },
        {
            title: 'Cantidad',
            dataIndex: 'cantidad',
            key: 'cantidad',
            align: 'center',
            width: '20px',
            render: (text) => <span style={{ fontWeight: 'bold' }}>{text}</span>,
            onHeaderCell: () => ({
                style: {
                    position: 'sticky',
                    top: 0,
                    background: '#ffffff',
                    zIndex: 1,
                },
            }),
        },
        {
            title: 'Foto',
            dataIndex: 'modelo',
            align: 'center',
            key: 'modelo',
            width: '30px',
            hidden: true,
            render: (text, row) => <span style={{fontWeight: 'bold', letterSpacing: '1px'}}><Button
                onClick={() => openModal(row)}
                type="link"
                icon={<CameraOutlined style={{fontSize: '25px'}}/>}
            />
            </span>,
            onHeaderCell: () => ({
                style: {
                    position: 'sticky',
                    top: 0,
                    background: '#ffffff',
                    zIndex: 1,
                },
            }),
        },

    ]
    return(
      <>
          {
              sp&&
              <Spiner/>
          }
          <Modal
              visible={visible}
              onCancel={onClose}
              ModalData={modelData}
              footer={null}
              centered
              title={`Detalle: ${descripcion} - Color: ${modelData?.toLowerCase()} Talla: ${tallaSelected}`}
              width={1000}
          >
              <Table
                  dataSource={dataSource}
                  columns={columns}
                  size="middle"
                  rowKey="id"
                  pagination={{ position: ['bottomCenter'], pageSize: 100 }}
              />


          </Modal>
      </>
  )
}
export default ColorModal;